import * as React from 'react';
import styled from 'styled-components';
import { getCountdownToSigningDay } from 'utils/countdownClock';

const IS_COUNTDOWN_ACTIVE = false;

const TickerBox = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
`;

const Item: React.FC<{ value: string; label: string }> = ({ value, label }) => {
  return (
    <div>
      <TickerBox className="flex w-[80px] items-center justify-center rounded-2xl px-4 py-6 text-3xl font-semibold text-color-text-grayscale-primary md:w-[105px] md:text-[56px] md:leading-[68px]">
        {value}
      </TickerBox>
      <div className="mt-1 text-lg leading-[29px] text-color-text-grayscale-secondary md:text-2xl">
        {label}
      </div>
    </div>
  );
};

const Countdown = () => {
  const [countdown, setCoundown] = React.useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    ms: '00',
  });
  const { days, hours, minutes, seconds } = countdown;

  React.useEffect(() => {
    const interval = setInterval(() => {
      const clock = getCountdownToSigningDay();
      setCoundown(clock);
    }, 1000);

    return () => clearInterval(interval);
  });

  if (!IS_COUNTDOWN_ACTIVE) {
    return null;
  }

  return (
    <div className="flex space-x-3 text-center">
      <div>
        <Item value={days} label="days" />
      </div>
      <div>
        <Item value={hours} label="hrs" />
      </div>
      <div>
        <Item value={minutes} label="min" />
      </div>
      <div>
        <Item value={seconds} label="sec" />
      </div>
    </div>
  );
};

export default Countdown;
